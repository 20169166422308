<script setup lang="ts">
import InViewObserver from '../../observers/InViewObserver'
import type { Intersection } from '../../observers/InViewObserver'
import { ref, onMounted } from 'vue'
const width = ref<number>(0)

const onScroll = (intersection: Intersection) => {
  width.value = (intersection.scrollPositionPercentage ?? 0) + 5
}

const observer = new InViewObserver(
  (intersection: Intersection) => {},
  '#app' ?? undefined,
  true,
  onScroll
)

interface InputProps {
  element: string
}

const props = withDefaults(defineProps<InputProps>(), {
  element: '#app'
})

onMounted(() => {
  if (!process.server) {
    const element = document.querySelector(props.element)
    if (element) {
      observer.observe(element)
    }
  }
})
</script>
<template>
  <div class="fixed h-1 top-0 w-full bg-white/[0.5] z-50">
    <div class="h-1 w-0 bg-white" :style="{ width: `${width}%` }"></div>
  </div>
</template>
