<script setup lang="ts">
import { useRoute } from 'vue-router'
import { ref } from 'vue'
const route = useRoute()

interface BreadCrumb {
  name: string
  path: string
}

interface BreadCrumbs {
  list: BreadCrumb[]
}

const breadcrumbs = ref<BreadCrumbs>({ list: [] })

if (route.meta?.breadcrumbs) {
  breadcrumbs.value.list = route.meta.breadcrumbs as BreadCrumb[]
  if (
    breadcrumbs.value.list &&
    breadcrumbs.value.list.filter(
      (item) => item.path === route.path || item.path + '/' === route.path
    ).length === 0
  ) {
    if (route.name === 'slug') {
      let lastSlug = route.path
      if (route.path.endsWith('/')) {
        lastSlug = lastSlug.substring(0, lastSlug.length - 1)
      }
      lastSlug = lastSlug.substring(lastSlug.lastIndexOf('/') + 1)

      lastSlug = lastSlug.replace(/-([a-z])/g, function (m, w) {
        return w.toUpperCase()
      })

      const title = lastSlug?.split(/(?=[A-Z])/).join(' ')

      breadcrumbs.value.list.push({
        name: title ?? '',
        path: route.path
      })
    } else {
      const title = route.name
        ?.toString()
        .split(/(?=[A-Z])/)
        .join(' ')
      breadcrumbs.value.list.push({
        name: title ?? '',
        path: route.path
      })
    }
  }
}
</script>

<template>
  <div class="text-white mb-8 md:mb-12">
    <nav aria-label="Breadcrumb" class="breadcrumb">
      <ul class="flex flex-row flex-wrap gap-2">
        <li
          class="text-white flex flex-row gap-2"
          v-for="(item, index) in breadcrumbs.list"
          :key="index"
        >
          <a
            class="flex flex-row gap-2 items-center duration-200 transition-all uppercase tracking-wider text-xs md:text-sm"
            :class="`${item.path === route.path ? 'text-secondary' : 'text-white/6 hover:text-secondary'}`"
            :href="item.path"
            :title="item.name"
          >
            <font-awesome-icon v-if="index == 0" icon="fa-solid fa-house" />
            {{ item.name }}
          </a>
          <span v-if="item.path !== route.path">/</span>
        </li>
      </ul>
    </nav>
  </div>
</template>
